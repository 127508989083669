// ** Initial State
const initialState = {
  countries: [],
  states: [],
  total: 1,
  params: {},

}

const location = (state = initialState, action) => {





  switch (action.type) {




    case 'GET_COUNTRY':
      return { 
        ...state, 
        countries: action.data 
      }



    case 'GET_STATE':
      return {
        ...state,
        states: action.data,
        params: action.params
    
      }



    default:
      return { ...state }
  }
}
export default location
