import mock from '../mock'
import jwt from 'jsonwebtoken'
import decodeJwt from 'jwt-decode';
import { toast, Slide } from 'react-toastify';
import '@styles/base/pages/page-auth.scss';
import {HelpCircle, Coffee } from 'react-feather';
import Avatar from '@components/avatar'



import axios from "axios";

//** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const jwtConfig = useJwt.jwtConfig




const data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      email: 'admin@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    }
  ]
}



//Login
mock.onPost('/jwt/login').reply(async(request) => {





  const { email, password } = JSON.parse(request.data);
  let error = {
    email: ['Something went wrong']
  }
  let incorrect = {
    message: "Incorrect user name or password"
  }

//New


var data = JSON.stringify({
  "identificationType": 1,
  "identification": email,
  "password": password
});

var config = {
  method: 'post',
  url: 'https://webapi.esspocket.net/api/v1/User/authenticate',
  headers: { 
    'Content-Type': 'application/json'
  
  },
  data : data
};

   const fetchedData = await axios(config)

  .then(function (res) {
 
   
    console.log(res)


  const decodedToken = decodeJwt(res.data.access_token);
    

  const exp = Date.now() + (decodedToken.exp * 1000);
 


 //Remove
  // localStorage.setItem('token', res.data.access_token);
  // localStorage.setItem('refresh_token', res.data.refreshToken)



  localStorage.setItem(jwtConfig.storageTokenKeyName, res.data.access_token)
  localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, res.data.refreshToken)
  localStorage.setItem('exp', exp);
  localStorage.setItem('user', decodedToken.Name + ' ' + decodedToken.Lastname);
  localStorage.setItem('permissions', 'distributor');
  localStorage.setItem('role', '5');



  



  const userOne = {
    id: 1,
    fullName: res.identity_lastname,
    username: decodedToken.Lastname,
    password: password,
    avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
    email: 'admin@demo.com',
    role: 'admin',
    ability: [
      {
        action: 'manage',
        subject: 'all'
      }
    ],
    extras: {
      eCommerceCartItemsCount: 5
    }
  }
  const response = {
    userData: userOne,
    accessToken: res.access_token,
    refreshToken:res.access_token
  }
  return [200, response]


})
.catch(function (error) {
  console.log(error);
});



//End new 


  
    
    return fetchedData;
  
    //Origin code ***************************

  // const user = data.users.find(u => u.email === email && u.password === password);
  // console.log(user);

  // if (user) {
  //   try {
  //     const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
  //     const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
  //       expiresIn: jwtConfig.refreshTokenExpireTime
  //     })

  //     const userData = { ...user }

  //     delete userData.password

  //     const response = {
  //       userData,
  //       accessToken,
  //       refreshToken
  //     }

  //     return [200, response]
  //   } catch (e) {
  //     error = e
  //   }
  // } else {
  //   error = {
  //     email: ['Email or Password is Invalid']
  //   }
  // }

  // return [400, { error }]
})


//Register

mock.onPost('/jwt/register').reply(request => {
  if (request.data.length > 0) {
    const { email, password, username } = JSON.parse(request.data)
    const isEmailAlreadyInUse = data.users.find(user => user.email === email)
    const isUsernameAlreadyInUse = data.users.find(user => user.username === username)
    const error = {
      email: isEmailAlreadyInUse ? 'This email is already in use.' : null,
      username: isUsernameAlreadyInUse ? 'This username is already in use.' : null
    }

    if (!error.username && !error.email) {
      const userData = {
        email,
        password,
        username,
        fullName: '',
        avatar: null,
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all'
          }
        ]
      }

      // Add user id
      const length = data.users.length
      let lastIndex = 0
      if (length) {
        lastIndex = data.users[length - 1].id
      }
      userData.id = lastIndex + 1

      data.users.push(userData)

      const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

      const user = Object.assign({}, userData)
      delete user['password']
      const response = { user, accessToken }

      return [200, response]
    } else {
      return [200, { error }]
    }
  }
})


//Refresh

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
