import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'





const ur = jwtDefaultConfig.spinallinkbaseURL;

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }



  
  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(config => {

        // ** Get token from localStorage
        const accessToken = this.getToken()

        // config.headers['Access-Control-Allow-Origin'] = '*';

        // config.headers['Cache-Control'] = 'no-cache';

       

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
       

          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`


        }

      
 

        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {

            this.isAlreadyFetchingAccessToken = true


            //Call Refresh
            this.refreshToken().then(r => {

              console.log(r)
              this.isAlreadyFetchingAccessToken = false
             
             // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.access_token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {

    return  localStorage.getItem('accessToken');

   // return localStorage.getItem(ur+this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(ur+this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {

    localStorage.setItem(ur+this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(ur+this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {

    // args.setHeader("Access-Control-Allow-Origin", "*")

 return axios.post(ur+this.jwtConfig.loginEndpoint, ...args)
  }




  twofactorvalidator({ identityId,value,token }) {



    let bodyParameters = JSON.stringify({
      "identityId": identityId,
      "value": value
    });



    const config = {
      headers: { 
      'Content-Type': 'application/json', 
     
      'Authorization': `Bearer ${token}`

      }
  };


    return axios.post(ur+this.jwtConfig.twofactorValidate, bodyParameters,config)

    
  }


  forgotPassword(...args) {





    return axios.post(ur+this.jwtConfig.forgotPasswordEndpoint, ...args)
  }


  setTempPassword(...args) {


    


    return axios.post(ur+this.jwtConfig.setTempPassword, ...args)
  }





  setPassword(...args) {



  


    return axios.post(ur+this.jwtConfig.setPasswordEndpoint, ...args)
  }



  

 



  register(...args) {
    return axios.post(ur+this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {



    return axios.post(ur+this.jwtConfig.refreshEndpoint, {refreshToken: this.getRefreshToken()})

   
  }




  addEmail(...args) {



  


    return axios.post(ur+'Identity/identityemailadd', ...args)
  }


  removeEmail(...args) {



  


    return axios.post(ur+'Identity/removeidentityemail', ...args)
  }




  addPhone(...args) {



  


    return axios.post(ur+'Identity/addidentityphone', ...args)
  }


  removePhone(...args) {



  


    return axios.post(ur+'Identity/removeidentityphone', ...args)
  }





}
