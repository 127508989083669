const initialState = {
  servicesData: {},
  params: {}
}

const services = (state = initialState, action) => {

 


  switch (action.type) {



   


    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }


    case 'GET_Services':


   

      return {
        ...state,
        servicesData: action.servicesData,
        params: action.params
      }

      case 'GET_HQ':
        return { ...state, selectedHQ: action.selectedHQ }


        case 'GET_ExternalCard':

      


          return { 
            ...state,
             externalCard: action.externalCard,
             params: action.params 
            }
  




    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default services
