import mock from '../mock'
import { paginateArray, sortCompare } from '../utils'

const data = {
  services: [

    {
      serviceID: 3,
      description: 'Apolo Taxi - PLAZA LAMA',
      config: {
        billingPeriod: '1',
        billingMode: '1',
        billingDay: '15',
        minimunBillingDays: '4',
        partialPaymentAllowed: 'false'
      },
      currency: 'DOP',
      Price: 1000
    },
    {
      serviceID: 4,
      description: 'Apolo Taxi - JUMBO SAN ISIDRO',
      config: {
        billingPeriod: '1',
        billingMode: '1',
        billingDay: '15',
        minimunBillingDays: '4',
        partialPaymentAllowed: 'false'
      },
      currency: 'DOP',
      Price: 1100
    },
    {
      serviceID: 5,
      description: 'Apolo Taxi - JUMBO LUPERON',
      config: {
        billingPeriod: '1',
        billingMode: '1',
        billingDay: '15',
        minimunBillingDays: '4',
        partialPaymentAllowed: 'false'
      },
      currency: 'DOP',
      Price: 1000
    },
    {
      serviceID: 6,
      description: 'Apolo Taxi - MEGA CENTRO',
      config: {
        billingPeriod: '1',
        billingMode: '1',
        billingDay: '15',
        minimunBillingDays: '4',
        partialPaymentAllowed: 'false'
      },
      currency: 'DOP',
      Price: 1000
    },
    {
      serviceID: 7,
      description: 'Apolo Taxi - JUMBO MELLA',
      config: {
        billingPeriod: '1',
        billingMode: '1',
        billingDay: '15',
        minimunBillingDays: '4',
        partialPaymentAllowed: 'false'
      },
      currency: 'DOP',
      Price: 1000
    }
    

    
    
  ]
}







// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/invoice/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null } = config
  /* eslint-enable */



  const queryLowered = q.toLowerCase()

  console.log(data.services)
  const filteredData = data.services
    // .filter(
    //   services =>
    //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    //     (services.client.description.toLowerCase().includes(queryLowered) ||
    //     services.client.description.toLowerCase().includes(queryLowered)) 
     
    // )
    // .sort(sortCompare('serviceID'))
    // .reverse()
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.services,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(config => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.services.map(services => services)

  return [200, clients]
})
