// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,

  productCurrency: '',
  totalCredit: 0,
  totalDebit: 0,
  beginningBalance: 0,
  endingBalance: 0,

  params: {}


}

const externalservices = (state = initialState, action) => {


// console.log('entro')
// console.log(action.type)

  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }


      
    case 'GET_EXTERNALSERVICESUMMARIZED_DATA':

      console.log(action)

      return {
        ...state,
        data: action.data,


        params: action.params
      }


      case 'GET_EXTERNALSERVICEDETAILED_DATA':

      console.log(action)

      return {
        ...state,
        data: action.data,


        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default externalservices
