

import React, { useRef, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/actions/auth'
import { isUserLoggedIn } from '@utils'





let timeout=localStorage.getItem('timeout');


const SessionTimeout = () => {


  



const dispatch = useDispatch()


const onPrompt = () => {
    // Fire a Modal Prompt

  }

  const onIdle = () => {


  
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (isUserLoggedIn() )

    {
      console.log('Sacar')
      dispatch(handleLogout())
      window.location.pathname = "/login";

    }
   

  

  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action


   // console.log(timeout)
    
  }

  const onAction = (event) => {
    // Do something when a user triggers a watched event
   // console.log(timeout)

  }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: timeout,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })




  const ResolveRoutes = () => {

console.log('other')

  }

return (



       
       

<></>




);
}
export default SessionTimeout;