const initialState = {
  servicesData: {},
  params: {}
}

const services = (state = initialState, action) => {

 


  switch (action.type) {



   


    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }

 

      case 'FIND_SERVICECONTRACT':

      console.log(action)

      return {
        ...state,
        serviceContracts: action.serviceContracts
      }



    case 'GET_Services':



      return {
        ...state,
        servicesData: action.servicesData,
        params: action.params
      }

      case 'GET_HQ':
        return { ...state, selectedHQ: action.selectedHQ }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default services
