// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,

  productCurrency: '',
  totalCredit: 0,
  totalDebit: 0,
  beginningBalance: 0,
  endingBalance: 0,

  params: {}


}

const transactions = (state = initialState, action) => {


// console.log('entro')
// console.log(action.type)

  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_TRANSACTION_DATA':

      //console.log('entro2')

      return {
        ...state,
        data: action.data,
        total: action.totalPages,


        productCurrency: action.productCurrency,
        totalCredit: action.totalCredit,
        totalDebit: action.totalDebit,
        beginningBalance: action.beginningBalance,
        endingBalance: action.endingBalance,

        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default transactions
